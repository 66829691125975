import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Layout, SliceZone } from '../components';

class Contact extends Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata,
        },
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      metaTitle,
      metaDescription,
      openGraphImage,
      body,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout location={location} seoData={seoData}>
        <SliceZone
          allSlices={body}
          siteMetadata={siteMetadata}
          location={location}
        />
      </Layout>
    );
  }
}

export default Contact;

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        blogSlug,
      }
    },
    page: prismicContact {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicContactBodyPageHero {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              description {
                text
              }
              illustrationStyle: illustration_style
              illustrator {
                document {
                  ... on PrismicArtist {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
              illustration {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              theme
            }
          }
          ... on PrismicContactBodyContactFormBlock {
            id
            sliceType: slice_type
            primary {
              title {
                text
              }
            }
          }
          ... on PrismicContactBodyHotlineAccordion {
            id
            sliceType: slice_type
            primary {
              title {
                text
              }
              subtitle {
                text
              }
            }
          }
        }
      }
    }
  }
`;
